import { Component } from '@angular/core';
import { Input } from '@angular/core';
import * as data from '@/app//shared/data/owl-carousel';
@Component({
  selector: 'app-brand-home',
  templateUrl: './brand-home.component.html',
  styleUrl: './brand-home.component.scss'
})
export class BrandHomeComponent {

  @Input() style: string = 'horizontal';
  @Input() class: string | null;
  @Input() contentClass: string;
  @Input() banners: any;
  
  public bannerSlider = data.customOptions4Item;

  ngOnChanges() {
    if (this.banners && this.banners) {
      for (let i = 0; i < this.banners.length; i++) {
        this.banners[i] = this.decodeURI(this.banners[i]);
      }
    }
  }

  decodeURI(banner: any) {
    if (banner && banner.redirect_link.link) {
      if (banner.redirect_link.link.includes('?')) {
        banner.path = banner.redirect_link.link.split('?')[0].split('/').pop();
        const urlParams = new URLSearchParams(banner.redirect_link.link);
        urlParams.forEach((value, key) => {
          const k = key.split('?')[1] ?? key;
          banner.params = {
            ...banner.params,
            [k]: value
          }
        });
      }
      else {
        banner.path = banner.redirect_link.link;
        banner.params = null;
      }
    }
    else {
      banner.path = "";
      banner.params = null;
    }

    return banner;
  }
}
