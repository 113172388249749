import { PostCategory, PostModel, PostPagingModel } from '@/app/shared/interface/blog.interface';
import { Breadcrumb } from '@/app/shared/interface/breadcrumb';
import { PaginationModel, RetriveUserModel } from '@/app/shared/interface/product.interface';
import { BlogService } from '@/app/shared/services/blog.service';
import { QueryStringService } from '@/app/shared/services/querystring.service';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Subscription, firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-news-home',
  templateUrl: './news-home.component.html',
  styleUrl: './news-home.component.scss'
})
export class NewsHomeComponent {

  complPosts: { name: string, dataDetail: PostModel[] }[] = [];

  itemsPerPage = 130;
  post: PostModel[];
  posts: PostModel[];
  public category_sidebar: PostCategory[] = [];
  public categorySlug: string[];

  constructor(private blogService: BlogService,
    private queryStringService: QueryStringService,
    @Inject(PLATFORM_ID) private platformId: string
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.getpostbycategory();
    }
    // this.getPosts();
  }
  //  //getPosts() {
  //     let body: RetriveUserModel = this.queryStringService.buildFetchingModel(this.itemsPerPage);
  //     this.blogService.getBlogs(body).subscribe({
  //       next: (res: PostPagingModel) => {
  //         if (res.data) {
  //           const combinedPosts: { name: string, dataDetail: PostModel[] }[] = [];

  //           let huongDanCount = 0;
  //           let chinhSachCount = 0;

  //           res.data.forEach(post => {
  //             post.categories.forEach(category => {
  //               if ((category.slug === 'huong-dan' && huongDanCount < 2) || (category.slug === 'chinh-sach' && chinhSachCount < 2)) {
  //                 let categoryName = category.slug === 'huong-dan' ? 'TIN TỨC' : 'ĐỒNG HÀNH CÙNG NHÀ THUỐC';
  //                 let existingCategory = combinedPosts.find(cp => cp.name === categoryName);

  //                 if (!existingCategory) {
  //                   combinedPosts.push({ name: categoryName, dataDetail: [post] });
  //                 } else {
  //                   if (category.slug === 'huong-dan' && huongDanCount < 3) {
  //                     existingCategory.dataDetail.push(post);
  //                     huongDanCount++;
  //                   } else if (category.slug === 'chinh-sach' && chinhSachCount < 3) {
  //                     existingCategory.dataDetail.push(post);
  //                     chinhSachCount++;
  //                   }
  //                 }
  //               }
  //             });
  //           });
  //           this.complPosts = combinedPosts;
  //         }
  //       },
  //       error: (err: any) => {
  //         console.log(err);
  //       },
  //       complete: () => {
  //       }
  //     });
  //   }//

  getpostbycategory() {
    this.blogService.getPinnedCategories().subscribe({
      next: (res: PostCategory[]) => {
      if (res) {
        this.category_sidebar = res;
        this.category_sidebar.forEach(category => {
          this.blogService.getPostByCategory(category.slug, 3).subscribe({
            next: (posts: PostModel[]) => {
              if (posts) {
                this.complPosts.push({ name: category.categoryName, dataDetail: posts });
              }
            },
            error: (err: any) => {
              console.log(err);
            }
          });
        });
      }
    }});
  }
}
