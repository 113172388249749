import { Component, Inject, Input, PLATFORM_ID, TransferState, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import * as feather from 'feather-icons';

import { AccountUser } from '@/app/shared/interface/account.interface';
import { ConfirmationModalComponent } from '@/app/shared/widgets/modal/confirmation-modal/confirmation-modal.component';
import { AuthService, TOKEN, USERNAME } from '@/app/shared/services/auth.service';
import { CommonService } from '@/app/shared/services/common.service';
import { WishlistService } from '../../services/wishlist.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-favorite-product',
  templateUrl: './favorite-product.component.html',
  styleUrls: ['./favorite-product.component.scss'],
})
export class FavoriteProductComponent {
  @Input() style: string = 'basic';

  @Input() isAuthenticated: boolean = false;
  user: any;
  username: any = '';
  totalQuantity: number;

  @ViewChild('confirmationModal') ConfirmationModal: ConfirmationModalComponent;

  constructor(
    private authService: AuthService,
    private commonService: CommonService,
    private wishlistService: WishlistService,
    private cookieService: SsrCookieService,
    private router: Router,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this.username = cookieService.get('username');
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.getWishlist();

      this.commonService.authEvent.subscribe({
        next: (data: boolean) => {
          this.isAuthenticated = data;
          if(this.isAuthenticated) {
            // this.username = this.cookieService.get('username');
            this.username = this.transferState.get(USERNAME, '');
          }
        },
      });
      
      this.commonService.getWishlist.subscribe({
        next: (res: number[]) => {
          this.totalQuantity = res.length
        }
      });
    }
  }

  getWishlist() {
    this.wishlistService.getWishlist().subscribe({
      next: (productIds: number[]) => {
        if (productIds) {
          this.commonService.setWishlist(productIds);
        }
      },
      error: (error: any) => {
        console.log(error);
      },
    })
  }

  logout(value: boolean) {
    if (value) {
      this.authService.logout();
      this.router.navigateByUrl("/", { replaceUrl: true });
    }
  }
}
