import { afterNextRender, AfterViewInit, Component, HostListener, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { WheelService } from '@/app/shared/services/wheel.service';
import { CodeStatus, Segment, SpinModel, VerifyCodeResponse, Wheel } from '@/app/shared/interface/wheel.interface';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { BaseResponse } from '@/app/shared/interface/common.interface';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { ConfigV2 } from '@/app/shared/interface/config.interface';

declare let Winwheel: any;
declare let TimelineMax: any;
declare let TweenMax: any;
declare var $: any;

@Component({
  selector: 'app-wheel',
  templateUrl: './wheel.component.html',
  styleUrl: './wheel.component.scss'
})

export class WheelComponent {

  // @ViewChild('bgMusic') bgMusic: any;
  @ViewChild('winMusic') winMusic: any;
  
  theWheel: any;
  wheelPower = 2;
  wheelSpinning = false;
  loadedImg: any;
  imgReward: string;
  txtReward = "";
  segments = [] as Segment[];
  hasMusicPlayed = false;
  tickAudio: any;
  clickAudio: any;
  winAudio: any;
  imageLoaded = false;

  remainingSpins = 0;
  wheelId: string = '';

  histories: any[] = [];
  tempHistory: any[] = [];

  config: any;

  body: any;

  configName = "MinigameConfig";

  constructor(
    private wheelService: WheelService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private toastService: ToastrService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
  ) {
    afterNextRender(() => {
      this.tickAudio = new Audio('/assets/audio/tick.mp3');
      this.clickAudio = new Audio('/assets/audio/click.mp3');
      this.winAudio = new Audio('/assets/audio/win.mp3');
    });
  }

  // @HostListener('document:click', ['$event'])
  // onClick(event: MouseEvent) {
  //   // Check if music has played and play it only once
  //   if (!this.hasMusicPlayed) {
  //     this.playBackgroundMusic();
  //   }
  // }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.getWheel();
    }
  }

  getWheel() {
    this.wheelService.getWheelConfig.subscribe({
      next: (res: Wheel) => {
        if (res && res.id) {
          this.wheelId = res.id!;
          if (res.wheelUIConfigJson) {
            this.config = JSON.parse(res.wheelUIConfigJson);
            this.config.rules.content = this.sanitizer.bypassSecurityTrustHtml(this.config.rules.content);
          }
        
          this.segments = res.segments!.map((item: any) => {
            return {
              orderNumber: item.orderNumber,
              text: item.segmentValue?.toString()
            };
          });

          this.getRemainingSpins(this.wheelId);
          this.getHistory();

          this.createTheWheel();
        }
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  getRemainingSpins(id: string) {
    this.wheelService.getRemainingSpins(id).subscribe({
      next: (res: BaseResponse) => {
        this.remainingSpins = res.data;
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  getHistory() {
    this.wheelService.getHistory(this.wheelId).subscribe({
      next: (res: BaseResponse) => {
        this.histories = res.data;
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  drawTheWheelImage() {
    if (isPlatformBrowser(this.platformId)) {
      this.loadedImg = new Image();
      this.loadedImg.onload = () => {
        const scale = Math.min(this.theWheel.outerRadius * 2 / this.loadedImg.width, this.theWheel.outerRadius * 2 / this.loadedImg.height);
        const scaledWidth = this.loadedImg.width * scale;
        const scaledHeight = this.loadedImg.height * scale;

        this.theWheel.wheelImage = this.loadedImg;
        this.theWheel.wheelImage.width = scaledWidth;
        this.theWheel.wheelImage.height = scaledHeight;
        this.theWheel.draw();
      };
      this.loadedImg.src = this.config.wheel.wheelImage;

      const currentWidth = window.innerWidth;
      window.resizeTo(currentWidth + 10, window.innerHeight);
      window.dispatchEvent(new Event('resize'));
      //console.log(window.innerWidth);
      window.resizeTo(currentWidth, window.innerHeight);
      window.dispatchEvent(new Event('resize'));
      //console.log(window.innerWidth);
    }
  }

  createTheWheel() {
    const canvas = this.document.getElementById("canvas");
    if (canvas) {

      // canvas.scrollIntoView({
      //   behavior: "smooth",
      //   block: "end",
      //   inline: "nearest"
      // });

      this.theWheel = new Winwheel({
        numSegments: this.segments.length,
        outerRadius: 180,
        drawMode: 'image',
        //drawText: true,
        centerX: canvas?.clientHeight ? canvas?.clientHeight / 2 : 300,
        centerY: canvas?.clientWidth ? canvas?.clientHeight / 2 : 300,
        //textFontSize: 25,
        //innerRadius: 5,
        //textOrientation: 'curved',
        responsive: true,
        segments: this.segments,
        animation:
        {
          type: 'spinToStop',
          duration: 7,
          spins: 8,
          callbackSound: this.playSpinningSound.bind(this),
          callbackFinished: this.alertPrize.bind(this),
          soundTrigger: 'pin'
        },
        pins: {
          number: 6,
          responsive: true,
          visible: false
        }
      });
  
      this.drawTheWheelImage();
    }
  }
  playSpinningSound() {
    this.tickAudio.pause();
    this.tickAudio.currentTime = 0;
    this.tickAudio.play();
  }
  playClickSound() {
    this.clickAudio.pause();
    this.clickAudio.currentTime = 0;
    this.clickAudio.play();
  }

  startSpin(): void {
    if (this.wheelSpinning === false) {
      if (this.wheelPower === 1) {
        this.theWheel.animation.spins = 3;
      } else if (this.wheelPower === 2) {
        this.theWheel.animation.spins = 8;
      } else if (this.wheelPower === 3) {
        this.theWheel.animation.spins = 15;
      }
    }

    this.theWheel.startAnimation(new TweenMax(new TimelineMax()));
    this.wheelSpinning = true;
  }

  resetWheel(): void {
    this.theWheel.stopAnimation(false);
    this.theWheel.rotationAngle = 0;
    this.theWheel.draw();

    this.wheelSpinning = false;
  }

  alertPrize(): void {
    this.wheelSpinning = false;

    $('#rewardModal').modal({
      backdrop: 'static',
      keyboard: false
    }).modal('show');
    this.playWinMusic();

    this.histories = this.tempHistory
  }

  getSegment(e: any): void {
    const clickedSegment = this.theWheel.getSegmentAt(e.clientX, e.clientY);
    //console.log('Segment clicked - ', clickedSegment);
  }

  calculatePrize(): void {
    const spinModel = {
      wheelId: this.wheelId
    } as SpinModel
    this.wheelService.spin(spinModel).subscribe({
      next: (res: BaseResponse) => {
        if (res && res.statusCode == 200) {
          const index = this.segments.findIndex(x => x.orderNumber == res.data.segment.orderNumber)
          
          this.imgReward = res.data.segment.rewards[0].rewardImageUrl;
          this.txtReward = res.data.segment.rewards[0].rewardName;

          // This formula always makes the wheel stop somewhere inside prize 3 at least
          // 1 degree away from the start and end edges of the segment.
          // const stopAt = (91 + Math.floor((Math.random() * 43)));
          // console.log('Stop at angle must lie between 90 and 135 degrees - ', stopAt);
          // const stopAt = (25 + Math.floor((Math.random() * 78)));
  
          const stopAt = this.theWheel.getRandomForSegment(index + 1);
  
          // Important thing is to set the stopAngle of the animation before stating the spin.
          this.theWheel.animation.stopAngle = stopAt;
          // May as well start the spin from here.
          this.startSpin();

          this.remainingSpins = res.data.spinAmount;
          this.tempHistory = res.data.history;

          //this.theWheel.animation.callbackFinished = console.log('This after animation ends - ', this.theWheel.getIndicatedSegment());
        }
        else if (res && res.statusCode == 400) {
          this.toastService.warning(res.message, '', {positionClass: 'toast-top-right'});
        }
      }
    });

  }

  submit(): void {
    this.resetWheel();
    this.playClickSound();
    this.calculatePrize();
  }

  // playBackgroundMusic() {
  //   // Play the background music only once
  //   if (this.bgMusic) {
  //     this.bgMusic.nativeElement.volume = 0.4;
  //     this.bgMusic.nativeElement.play();
  //     this.hasMusicPlayed = true;
  //   }
  // }

  playWinMusic() {
    this.winAudio.pause();
    this.winAudio.currentTime = 0;
    this.winAudio.play();
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }
}