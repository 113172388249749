import { Menu } from '@/app/shared/interface/menu.interface';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PostCategoryWithPost, PostModel } from '@/app/shared/interface/blog.interface';
import { isPlatformBrowser } from '@angular/common';
// import $ from 'jquery';

declare var $: any;

@Component({
  selector: 'app-blog-post-menu',
  templateUrl: './blog-post-menu.component.html',
  styleUrl: './blog-post-menu.component.scss'
})
export class BlogPostMenuComponent {
  @Input() blogPostTag: string;
  private MenuTag = ["h2", "h3", "h4", "h5", "h6"];
  menuItem: string = '';
  public routerEvent: any;

  menuItems: Menu[] = [];
  newPath: string = '';

  private currentPath: string = '';

  constructor(private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    // afterRender(() => {
    //   if (this.blogPostTag) {
    //     this.currentPath = this.router.url;
    //     this.menuItems = this.getMenuItems($(this.blogPostTag).find("h2"));
    //   }
    // });
  }

  ngAfterViewChecked(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.currentPath = this.router.url
      if (this.newPath != this.currentPath) {
        this.menuItems = this.getMenuItems($(this.blogPostTag).find("h2"));
        this.newPath = this.router.url;
        if (this.currentPath.includes('#') || this.currentPath.includes('?')) {
          try {
            let tree = this.router.parseUrl(this.currentPath);
            this.currentPath = tree.root.children['primary'].segments[0].path;
          } catch {
            this.currentPath = this.router.url;
          }
        }
      }
    }
  }

  ngOnInit() {
    this.currentPath = this.router.url;
    if (this.currentPath.includes('#') || this.currentPath.includes('?')) {
      try {
        let tree = this.router.parseUrl(this.currentPath);
        this.currentPath = tree.root.children['primary'].segments[0].path;
      } catch {
        this.currentPath = this.router.url;
      }
    }
  }

  getMenuItems(elements: any, currentTag: string = "h2"): Menu[] {
    if (!elements.length) {
      return [];
    }
    let currentCursor = this.MenuTag.indexOf(currentTag);
    let nextCursor = (currentCursor == this.MenuTag.length - 1 || currentCursor < 0) ? -1 : (currentCursor + 1);

    let menuItems = [] as Menu[];
    elements.each((i: any, e: any) => {

      let subMenu = [] as Menu[];
      if (nextCursor > 0) {
        let nextTag = this.MenuTag[nextCursor];
        subMenu = this.getMenuItems($(e).nextUntil(currentTag).filter(nextTag), nextTag);
      }
      let text = $(e).text();
      let id = this.slugify(text);

      this.setAttributeForTitle(e, id);

      let item: Menu = {
        path: this.getMenuItemPath(id),
        title: text,
        children: subMenu
      }

      menuItems.push(item);
    });
    return menuItems;
  }

  setAttributeForTitle(e: HTMLElement, id: string) {
    $(e).attr('id', id).attr('name', id);
  }

  getMenuItemPath(id: string) {
    let tree = this.router.parseUrl(this.currentPath);
    this.currentPath = tree.root.children['primary'].segments[0].path;
    return this.currentPath + '#' + id;

  }

  slugify(str: string): string {
    const a = 'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;'
    const b = 'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz______'
    const p = new RegExp(a.split('').join('|'), 'g')
    return str.trim().toString().toLowerCase()
      .replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a')
      .replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e')
      .replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i')
      .replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o')
      .replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u')
      .replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y')
      .replace(/đ/gi, 'd')
      .replace(/\s+/g, '_')
      .replace(p, c => b.charAt(a.indexOf(c)))
      .replace(/&/g, '_and_')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '_')
      .replace(/^-+/, '')
      .replace(/-+$/, '');
  }
}


