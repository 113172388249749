<ul class="navbar-nav">
  <div *ngTemplateOutlet="recursiveMenu; context: { $implicit: menu }"></div>
  <ng-template #recursiveMenu let-menus>
    <ng-container *ngFor="let menu of menus">
      <li
        *ngIf="menu.visible"
        [ngClass]="[
          menu.type == 'sub' && menu.children
            ? 'nav-item dropdown'
            : 'nav-item',
          menu.megaMenu ? 'dropdown-mega' : ''
        ]"
        [class.new-nav-item]="menu.badge"
      >
        <a
          class="nav-link dropdown-toggle"
          *ngIf="menu.type === 'sub'"
          (click)="toggle(menu)"
        >
          {{ menu.title | translate }}
          <label *ngIf="menu.badge" class="new-dropdown">{{
            menu.badge | translate
          }}</label>
        </a>
        <a
          (click)="closeMenuOnClick(menu)"
          class="nav-link"
          [routerLink]="menu.path"
          [class.show]="menu.active"
          [queryParams]="menu.params"
          *ngIf="menu.type === 'link'"
        >
          
          {{ menu.title | translate }}
          <label
              class="menu-label {{ menu.labelClass }}"
              *ngIf="menu.label"
              >{{ menu.label | translate }}</label
            >
            <img
              src="https://fptcity.vn/wp-content/uploads/hot-icon.gif"
              style="width: 20px; margin-top: -20px;" *ngIf="menu.isHot"
            />
        </a>
        <div
          class="dropdown-menu dropdown-menu-2"
          [class.show]="menu.active"
          *ngIf="menu.megaMenu && menu.megaMenuType === 'link'"
        >
          <div class="row">
            <div
              class="dropdown-column col-xl-3"
              *ngFor="let megaMenu of menu.children"
            >
              <ng-container *ngFor="let link of megaMenu.children">
                <h5
                  class="dropdown-header {{ link.class }}"
                  *ngIf="link.type === 'sub'; else links"
                >
                  {{ link.title | translate }}
                </h5>
                <ng-template #links>
                  <a
                    class="dropdown-item"
                    [routerLink]="link.path"
                    [queryParams]="link.params"
                    *ngIf="link.type === 'link'"
                  >
                    {{ link.title | translate }}
                    <label
                      class="menu-label {{ link.labelClass }}"
                      *ngIf="link.label"
                      >{{ link.label | translate }}</label
                    >
                  </a>
                  <a
                    href="{{ link.path }}"
                    class="dropdown-item"
                    *ngIf="link.type === 'external_link'"
                    target="_blank"
                  >
                    {{ link.title | translate }}
                    <label
                      class="menu-label {{ link.labelClass }}"
                      *ngIf="link.label"
                      >{{ link.label | translate }}</label
                    >
                  </a>
                </ng-template>
              </ng-container>
            </div>
            <div
              class="dropdown-column col-xl-6 d-xl-block d-none"
              *ngIf="menu.slider === 'product'"
            >
              <div class="menu-product-slider">
                <div class="row">
                  <div class="col-6" *ngFor="let product of products">
                    <app-product-box
                      [product]="product"
                      [class]="'product-box-3'"
                      [style]="'horizontal'"
                    ></app-product-box>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="dropdown-column col-xl-3 d-xl-block d-none"
              *ngIf="menu.slider === 'banner_portrait'"
            >
              <div class="menu-img-banner">
                <a
                  routerLink="/product/deliciously-sweet-watermelon"
                  class="text-title"
                >
                  <img src="assets/images/mega-menu.png" alt="banner" />
                </a>
              </div>
            </div>
            <div
              class="dropdown-column col-xl-12 d-xl-block d-none"
              *ngIf="menu.slider === 'banner_landscape'"
            >
              <div
                class="menu-img-banner rounded overflow-hidden mx-0 mt-3 mb-0"
              >
                <img src="assets/images/mega-menu2.png" class="img-fluid" />
              </div>
            </div>
            <div
              class="dropdown-column col-xl-9 d-xl-block d-none"
              *ngIf="menu.slider === 'blog'"
            >
              <div class="row">
                <div class="col-6" *ngFor="let blog of blogs">
                  <div class="blog-box sticky-blog">
                    <div class="blog-box-image">
                      <a
                        [routerLink]="['/blog/', blog?.slug]"
                        class="blog-image"
                      >
                        <img
                          [src]="
                            blog.blog_thumbnail
                              ? blog?.blog_thumbnail?.original_url
                              : 'assets/images/product.png'
                          "
                          class="img-fluid"
                          [alt]="blog.title"
                        />
                      </a>
                    </div>

                    <a
                      [routerLink]="['/blog/', blog?.slug]"
                      class="blog-detail"
                      tabindex="0"
                    >
                      <h6>
                        {{ blog.created_at | date : "dd MMM yyyy hh:mm:a" }}
                      </h6>
                      <h5>{{ blog.title }}</h5>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="dropdown-menu dropdown-menu-2 dropdown-image"
          [class.show]="menu.active"
          *ngIf="menu.megaMenu && menu.megaMenuType === 'image'"
        >
          <div class="dropdown-column">
            <a
              href="javascript:void(0)"
              class="dropdown-item text-center"
              *ngFor="let imageMenu of menu.children"
              [routerLink]="imageMenu.path"
            >
              <img
                [src]="imageMenu.image"
                class="img-fluid"
                [alt]="imageMenu.title"
              />
              <span>{{ imageMenu.title | translate }}</span>
            </a>
          </div>
        </div>
        <ul
          class="dropdown-menu"
          *ngIf="menu.children && !menu.megaMenu"
          [class.show]="menu.active"
        >
          <ng-container
            *ngTemplateOutlet="
              recursiveMenu;
              context: { $implicit: menu.children }
            "
          ></ng-container>
        </ul>
      </li>
    </ng-container>
  </ng-template>
</ul>
