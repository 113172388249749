import { Component, Input } from '@angular/core';
import * as data from "@/app/shared/data/owl-carousel";
import {customOptions1Item} from "@/app/shared/data/owl-carousel";

@Component({
  selector: 'app-home-banner',
  templateUrl: './home-banner.component.html',
  styleUrl: './home-banner.component.scss'
})
export class HomeBannerComponent {

  @Input() data: any;
  @Input() banners: any;
  numbers: number[] = Array.from({ length: 2 }, (_, index) => index + 1);
  public bannerSlider = data.customOptions1Item;

  ngOnChanges() {
    if (this.data && this.data.main_banner) {
      for (let i = 0; i < this.data.main_banner.length; i++) {
        this.data.main_banner[i] = this.decodeURI(this.data.main_banner[i]);
      }
    }

    if (this.data && this.data.sub_banner_1) {
      this.decodeURI(this.data?.sub_banner_1);
    }
    
    if (this.data && this.data.sub_banner_2) {
      this.decodeURI(this.data?.sub_banner_2);
    }
  }

  decodeURI(banner: any) {
    if (banner && banner.redirect_link.link) {
      if (banner.redirect_link.link.includes('?')) {
        banner.path = banner.redirect_link.link.split('?')[0].split('/').pop();
        const urlParams = new URLSearchParams(banner.redirect_link.link);
        urlParams.forEach((value, key) => {
          const k = key.split('?')[1] ?? key;
          banner.params = {
            ...banner.params,
            [k]: value
          }
        });
      }
      else {
        banner.path = banner.redirect_link.link;
        banner.params = null;
      }
    }
    else {
      banner.path = "";
      banner.params = null;
    }

    return banner;
  }
}
