import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSliderModule } from 'ngx-slider-v2';
import { SwiperModule } from 'swiper/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { Select2Module } from 'ng-select2-component';
import { NgxImageZoomModule } from 'ngx-image-zoom';

// Components
import { NoticeComponent } from '@/app/layout/header/notice/notice.component';
import { TopbarComponent } from '@/app/layout/header/topbar/topbar.component';
import { NavBarComponent } from '@/app/layout/header/nav-bar/nav-bar.component';
import { FooterComponent } from '@/app/layout/footer/footer.component';

import { HomeComponent } from '@/app/components/home/home.component';
import { HomeBannerComponent } from './widgets/home-banner/home-banner.component';
import { BannerComponent } from './widgets/banner/banner.component';
import { ProductComponent } from '@/app/components/product/product.component';
import { ProductDetailComponent } from '@/app/components/product/product-detail/product-detail.component';
import { CartComponent } from '@/app/components/cart/cart.component';
import { QuickOrderComponent } from '@/app/components/quick-order/quick-order.component';
import { FlashSaleComponent } from '@/app/components/flash-sale/flash-sale.component';

// Widgets
import { ButtonComponent } from './widgets/button/button.component';
import { SearchBarComponent } from './widgets/search-bar/search-bar.component';
import { SearchBoxComponent } from './widgets/search-box/search-box.component';
import { ConfirmationModalComponent } from './widgets/modal/confirmation-modal/confirmation-modal.component';
import { QuickCartComponent } from './widgets/quick-cart/quick-cart.component';
import { CategoriesComponent } from './widgets/categories/categories.component';
import { AlertComponent } from './widgets/alert/alert.component';
import { DeleteModalComponent } from './widgets/modal/delete-modal/delete-modal.component';
import { ImageLinkComponent } from './widgets/image-link/image-link.component';
import { MenuComponent } from './widgets/menu/menu.component';
import { MobileMenuComponent } from './widgets/mobile-menu/mobile-menu.component';
import { ProductDetailModalComponent } from './widgets/modal/product-detail-modal/product-detail-modal.component';
import { PaginationComponent } from './widgets/pagination/pagination.component';
import { ProductBoxComponent } from './widgets/product-box/product-box.component';
import { ProductBoxHorizontalComponent } from './widgets/product-box/product-box-horizontal/product-box-horizontal.component';
import { ProductBoxVerticalComponent } from './widgets/product-box/product-box-vertical/product-box-vertical.component';
import { SkeletonProductBoxComponent } from './widgets/product-box/skeleton-product-box/skeleton-product-box.component';
import { RecentPurchasePopupComponent } from './widgets/recent-purchase-popup/recent-purchase-popup.component';
import { StickyCartComponent } from './widgets/sticky-cart/sticky-cart.component';
import { StickyCompareComponent } from './widgets/sticky-compare/sticky-compare.component';
import { ThemeCustomizerComponent } from './widgets/theme-customizer/theme-customizer.component';
import { VariantAttributesComponent } from './widgets/variant-attributes/variant-attributes.component';
import { CategoriesBlockComponent } from './widgets/categoriesblock/categories.component';
import { MyAccountComponent } from './widgets/my-account/my-account.component';
import { LogoComponent } from './widgets/logo/logo.component';
import { LanguageComponent } from './widgets/language/language.component';
import { FooterLogoComponent } from './widgets/footer-logo/logo.component';
import { AboutComponent } from './widgets/about/about.component';
import { LinksComponent } from './widgets/links/links.component';
import { SocialLinksComponent } from './widgets/social-links/social-links.component';
import { BreadcrumbComponent } from './widgets/breadcrumb/breadcrumb.component';
import { CollectionSidebarComponent } from './widgets/sidebar/sidebar.component';
import { CollectionSortComponent } from './widgets/collection-sort/collection-sort.component';
import { CollectionPaginateComponent } from './widgets/collection-paginate/collection-paginate.component';
import { CollectionFilterComponent } from './widgets/collection-filter/collection-filter.component';
import { BackToTopComponent } from './widgets/back-to-top/back-to-top.component';
import { ProductContainComponent } from './widgets/product-contain/product-contain.component';
import { CounterComponent } from './widgets/counter/counter.component';
import { NoDataComponent } from './widgets/no-data/no-data.component';
import { ProductActionComponent } from './widgets/product-action/product-action.component';
import { ProductInformationComponent } from './widgets/product-information/product-information.component';
import { ProductDeliveryInformationComponent } from './widgets/product-delivery-information/product-delivery-information.component';
import { PaymentOptionComponent } from './widgets/payment-option/payment-option.component';
import { ProductSocialShareComponent } from './widgets/product-social-share/product-social-share.component';
import { RelatedProductsComponent } from './widgets/related-products/related-products.component';
import { TitleComponent } from './widgets/title/title.component';
import { ProductDetailsAccordionComponent } from './widgets/product-details-accordion/product-details-accordion.component';
import { ProductDetailsTabsComponent } from './widgets/product-details-tabs/product-details-tabs.component';
import { CollectionCategoryFilterComponent } from './widgets/filter/collection-category-filter/collection-category-filter.component';
import { ProductDetailImageModalComponent } from './widgets/modal/product-detail-image-modal/product-detail-image-modal.component';
import { LoginModalComponent } from './widgets/login-modal/login-modal.component';
import { RegistryModalComponent } from './widgets/registry-modal/registry-modal.component';
import { ServiceComponent } from './widgets/service/service.component';
import { WserviceComponentComponent } from './widgets/wservice-component/wservice-component.component';
import { LoaderComponent } from './widgets/loader/loader.component';
import { FeedbackHomeComponent } from './widgets/feedback-home/feedback-home.component';
import { RegistryInfoHomeComponent } from './widgets/registry-info-home/registry-info-home.component';
import { PartnerLogoComponent } from './widgets/partner-logo/partner-logo.component';
import { NewsHomeComponent } from './widgets/news-home/news-home.component';
import { CategoriesHomeComponent } from './widgets/categories-home/categories-home.component';
import { BrandHomeComponent } from './widgets/brand-home/brand-home.component';
import { TopSaleHomeComponent } from './widgets/top-sale-home/top-sale-home.component';
import { FlashsaleHomeComponent } from './widgets/flashsale-home/flashsale-home.component';
import { Error404Component } from '../components/page/error404/error404.component';
import { SidebarComponent } from './widgets/sidebar-account/sidebar.component';
import { AddressModalComponent } from './widgets/address-modal/address-modal.component';

// Pipes
import { CurrencySymbolPipe } from './pipe/currency-symbol.pipe';
import { TitleCasePipe } from './pipe/title-case.pipe';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { FileUploadComponent } from './widgets/file-upload/file-upload.component';
import { BlogComponent } from '@/app/components/blog/blog.component';
import { BlogStaticPageComponent } from '@/app/components/blog/blog-static-page/blog-static-page.component';
import { BlogPostComponent } from '@/app/components/blog/blog-post/blog-post.component';
import { NavNotiComponent } from "@/app/shared/widgets/nav-noti/nav-noti.component";
import { FavoriteProductComponent } from "@/app/shared/widgets/favorite-product/favorite-product.component";
import { BlogListComponent } from '@/app/components/blog/blog-list/blog-list.component';
import { BlogPinListComponent } from '@/app/components/blog/blog-list/blog-pin-list/blog-pin-list.component';
import { BlogCategoryListComponent } from '@/app/components/blog/blog-list/blog-category-list/blog-category-list.component';
import { BlogCardComponent } from '@/app/components/blog/blog-card/blog-card.component';
import { BlogPostMenuComponent } from '@/app/components/blog/blog-post-menu/blog-post-menu.component';
import { SummaryPipe } from './pipe/summary.pipe';
import { ThankYouComponent } from '../components/thank-you/thank-you.component';
import { BlogRatingComponent } from '../components/blog/blog-rating/blog-rating.component';
import { PartnerLogoSvgComponent } from './widgets/partner-logo/partner-svg/partner-logo-svg.component';
import { IngredientComponent } from '../components/ingredient/ingredient.component';
import { ForgotPasswordModalComponent } from "@/app/shared/widgets/forgot-password-modal/forgot-password-modal.component";
import { VoucherModalComponent } from './widgets/modal/voucher-modal/voucher-modal.component';
import { VoucherDetailModalComponent } from './widgets/modal/voucher-modal/voucher-detail-modal/voucher-detail-modal.component';
import { QrCodeComponent } from './widgets/qr-code/qr-code.component';
import { RegistryModalV2Component } from './widgets/registry-modal-v2/registry-modal-v2.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { IncompleteInfoWarningComponent } from './widgets/incomplete-info-warning/incomplete-info-warning.component';
import { CartBCTComponent } from '../components/cart-bct/cart-bct.component';
import { AddressModalBCTComponent } from './widgets/address-modal-bct/address-modal-bct.component';
import { CounterGuestComponent } from './widgets/counter-guest/counter-guest.component';
import { ExchangeGiftModalComponent } from './widgets/modal/exchange-gift-modal/exchange-gift-modal.component';
import { GiftComponent } from '../components/account/gift/gift.component';
import { CounterGiftComponent } from './widgets/counter-gift/counter-gift.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from '@/environments/environment';
import { LoyaltyComponent } from '../components/account/loyalty/loyalty.component';
import { WheelComponent } from '../components/wheel/wheel.component';


const components = [
  NoticeComponent,
  TopbarComponent,
  FooterComponent,
  NavBarComponent,
  ButtonComponent,
  SearchBarComponent,
  SearchBoxComponent,
  ConfirmationModalComponent,
  QuickCartComponent,
  CartComponent,
  CartBCTComponent,
  CategoriesComponent,
  AlertComponent,
  DeleteModalComponent,
  ImageLinkComponent,
  MenuComponent,
  ProductDetailModalComponent,
  PaginationComponent,
  ProductBoxComponent,
  ProductBoxHorizontalComponent,
  ProductBoxVerticalComponent,
  SkeletonProductBoxComponent,
  RecentPurchasePopupComponent,
  StickyCartComponent,
  StickyCompareComponent,
  ThemeCustomizerComponent,
  VariantAttributesComponent,
  CategoriesBlockComponent,
  MyAccountComponent,
  HomeComponent,
  HomeBannerComponent,
  BannerComponent,
  LogoComponent,
  LanguageComponent,
  FooterLogoComponent,
  AboutComponent,
  LinksComponent,
  SocialLinksComponent,
  BreadcrumbComponent,
  ProductComponent,
  CollectionSidebarComponent,
  CollectionSortComponent,
  CollectionPaginateComponent,
  ProductDetailComponent,
  MobileMenuComponent,
  CollectionFilterComponent,
  BackToTopComponent,
  ProductContainComponent,
  CounterComponent,
  NoDataComponent,
  QuickOrderComponent,
  ProductActionComponent,
  ProductInformationComponent,
  ProductDeliveryInformationComponent,
  PaymentOptionComponent,
  ProductSocialShareComponent,
  ProductDetailsTabsComponent,
  ProductDetailsAccordionComponent,
  RelatedProductsComponent,
  TitleComponent,
  CollectionCategoryFilterComponent,
  ProductDetailImageModalComponent,
  LoginModalComponent,
  RegistryModalComponent,
  ForgotPasswordModalComponent,
  ServiceComponent,
  WserviceComponentComponent,
  LoaderComponent,
  RegistryInfoHomeComponent,
  SidebarComponent,
  PartnerLogoComponent,
  FeedbackHomeComponent,
  NewsHomeComponent,
  CategoriesHomeComponent,
  BrandHomeComponent,
  TopSaleHomeComponent,
  FlashsaleHomeComponent,
  Error404Component,
  FlashSaleComponent,
  FileUploadComponent,
  BlogPostMenuComponent,
  BlogCardComponent,
  BlogPinListComponent,
  BlogCategoryListComponent,
  BlogListComponent,
  BlogStaticPageComponent,
  BlogPostComponent,
  BlogComponent,
  BlogRatingComponent,
  AddressModalComponent,
  AddressModalBCTComponent,
  NavNotiComponent,
  FavoriteProductComponent,
  ThankYouComponent,
  PartnerLogoSvgComponent,
  IngredientComponent,
  VoucherModalComponent,
  VoucherDetailModalComponent,
  QrCodeComponent,
  RegistryModalV2Component,
  IncompleteInfoWarningComponent,
  CounterGuestComponent,
  ExchangeGiftModalComponent,
  GiftComponent,
  CounterGiftComponent,
  LoyaltyComponent,
  WheelComponent
]

const modules = [
  CommonModule,
  RouterModule,
  CarouselModule,
  NgbModule,
  ReactiveFormsModule,
  FormsModule,
  NgxSliderModule,
  SwiperModule,
  TranslateModule,
  CurrencySymbolPipe,
  TitleCasePipe,
  SummaryPipe,
  Select2Module,
  NgxImageZoomModule,
  NgxDropzoneModule,
  NgOtpInputModule,
  RecaptchaV3Module
]
@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    ...modules
  ],
  exports: [
    ...components,
    ...modules
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
  ],
})
export class SharedModule { }
