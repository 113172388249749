<div class="text-center section-container w-100 pb-3">
  <!-- <audio #bgMusic loop>
    <source src="/assets/audio/bg.mp4" type="audio/mpeg" />
    Your browser does not support the audio tag.
  </audio> -->
  <div class="bg-container">
    <div class="div-container align-items-center">
      <div
        class="d-lg-flex d-xl-flex d-xxl-flex d-md-block d-sm-block justify-content-between pt-3"
      >
        <div
          class="d-none d-lg-grid d-xl-grid justify-content-between pt-5 gap-5"
          style="width: 30%"
        >
          <div *ngIf="config"
            class="d-flex flex-wrap"
            style="font-size: calc(2.5rem + 0.00125 * (100vw - 320px))"
          >
            <div class="theme-color fw-bold">{{ config.title }}</div>
          </div>
          <ng-template [ngTemplateOutlet]="rules"></ng-template>
        </div>
        <div
          class="d-lg-none d-xxl-none d-xl-none d-md-flex d-sm-flex flex-wrap pb-5"
          style="
            font-size: calc(2.5rem + 0.00125 * (100vw - 320px));
            display: flex;
            justify-content: center;
          "
        >
          <div *ngIf="config" style="width: 70%" class="theme-color fw-bold">
            {{ config.title }}
          </div>
        </div>
        <div class="the_wheel" id="the_wheel">
          <img id="prizePointer" *ngIf="config" [src]="config.wheel.pointerImage" alt="V" />
          <canvas
            (click)="getSegment($event)"
            id="canvas"
            width="360px"
            height="360px"
            data-responsiveMinWidth="180"
            data-responsiveScaleHeight="true"
            data-responsiveMargin="50"
          >
            Trình duyệt của bạn không hỗ trợ canvas, vui lòng sử dụng trình
            duyệt mới hơn
          </canvas>
          <div class="pt-3" *ngIf="config">
            <button
              class="bg-transparent border-0 p-0"
              data-bs-target="#inputModal"
              [disabled]="wheelSpinning"
              (click)="submit()"
            >
              <img [src]="config.wheel.spinButtonImage" class="w-75" />
            </button>
            <div class="theme-color fs-5 py-2">
              Còn {{ ("0" + remainingSpins).slice(-2) }} lượt quay
            </div>
          </div>
        </div>
        <div *ngIf="config"
          class="d-lg-none d-xl-none d-xxl-none d-flex d-flex justify-content-between px-3 pb-3 pt-4 w-100 gap-3"
        >
          <button
            [disabled]="wheelSpinning"
            class="wheel-btn-show-modal"
            data-bs-target="#rulesModal"
          >
            {{ config.rules.btnLabel }}
          </button>
          <div style="border: 1px solid var(--theme-color)"></div>
          <button
            [disabled]="wheelSpinning"
            class="wheel-btn-show-modal"
            data-bs-target="#historyModal"
          >
          {{ config.histories.btnLabel }}
          </button>
        </div>
        <div class="d-none d-lg-flex d-xl-flex d-xxl-flex" style="width: 30%">
          <ng-template [ngTemplateOutlet]="history"></ng-template>
        </div>
      </div>
    </div>
  </div>

  <!-- Template rules -->
  <ng-template #rules>
    <div *ngIf="config"
      style="
        background-color: rgba(255, 255, 255, 0.6);
        border-radius: 15px;
        padding: 1px;
        box-shadow: 0 0 7px 0 rgba(98, 156, 30, 0.6);
      "
    >
      <div class="wheel-rules">
        <div class="d-flex gap-2 align-items-center px-3 pt-2">
          <img [src]="config.rules.icon" />
          <span
            class="theme-color fw-bold"
            style="font-size: calc(1rem + 0.00125 * (100vw - 320px))"
            >{{ config.rules.title }}</span
          >
        </div>
        <div [innerHTML]="config.rules.content"></div>
      </div>
    </div>
  </ng-template>

  <!-- Template history -->
  <ng-template #history>
    <div *ngIf="config"
      class="d-flex history-panel w-100"
      style="max-height: 497px; border: 1px solid #82b94133 !important"
    >
      <div class="header">
        <div class="d-flex align-items-center px-3 py-2 gap-2 mb-3">
          <img [src]="config.histories.icon" />
          <span class="text-white fs-5 fw-bold">{{
            config.histories.title
          }}</span>
        </div>
      </div>
      <div class="history text-start">
        <table class="table table-borderless d-flex">
          <tbody>
            <tr>
              <th>Thời gian</th>
              <th>Quà tặng</th>
            </tr>
            <tr *ngFor="let item of histories">
              <td>{{ item.spinDate | date : "dd/MM/yyyy HH:mm:ss" }}</td>
              <td>
                <ng-container *ngFor="let reward of item.result.rewards; let i = index">
                  {{ reward.rewardName }} <span *ngIf="i < item.result.rewards.length - 1">| </span>
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-template>

  <!-- Modal rules -->
  <div *ngIf="config"
    class="modal fade"
    id="rulesModal"
    tabindex="-1"
    aria-labelledby="rulesModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog h-100 d-flex align-items-center justify-content-center"
    >
      <div
        class="d-flex history-panel w-100"
        style="max-height: 497px; border: 1px solid #82b94133 !important"
      >
        <div class="modal-content h-75 bg-transparent border-0">
          <div class="modal-body p-0">
            <div class="header">
              <div class="d-flex align-items-center px-3 py-2 gap-2 mb-3">
                <img [src]="config.rules.iconAlt" />
                <span class="text-white fs-5 fw-bold">
                  {{ config.rules.title }}
                </span>
              </div>
            </div>
            <div class="history text-start">
              <div [innerHTML]="config.rules.content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal history -->
  <div *ngIf="config"
    class="modal fade"
    id="historyModal"
    tabindex="-1"
    aria-labelledby="historyModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog h-100 d-flex align-items-center justify-content-center"
    >
      <div class="modal-content h-75 bg-transparent border-0">
        <div class="modal-body d-flex">
          <ng-template [ngTemplateOutlet]="history"></ng-template>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Reward -->
  <div
    class="modal fade"
    id="rewardModal"
    tabindex="-1"
    aria-labelledby="rewardModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <!-- Modal mody -->
          <div
            class="mx-auto d-flex align-items-center justify-content-center position-relative pt-5"
          >
            <button
              type="button"
              style="
                top: 3rem;
                border-radius: 999px;
                opacity: 1;
                font-size: 0.5rem;
              "
              class="bg-light btn-close position-absolute end-0 mt-1 me-1 p-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <img
              (load)="handleImageLoad()"
              src="/assets/images/minigames/wheel/pop-up-bg.png"
              class="w-100"
            />
            <img
              (load)="handleImageLoad()"
              [src]="imgReward"
              class="w-50 position-absolute"
              style="top: 50%"
              alt="{{ txtReward }}"
            />
<!--            <div-->
<!--              class="reward-text position-absolute text-white fw-bold fs-1"-->
<!--            >-->
<!--              {{ txtReward }}-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
