<section *ngIf="relatedproducts.length > 0">
  <div class="container-fluid-lg container-fluid-md container-fluid-xs px-2">
    <img src="assets/svg/tips 1.svg" class="me-3" />
    <app-title [title]="title"></app-title>
    <div class="product-section">
      <div class="product-left-box">
        <div class="product-main-1 no-arrow">
          <owl-carousel-o
            [options]="productSimilartSlider"
            id="owlCar"
            #owlCar
            class="product-slide"
          >
            <ng-container *ngIf="relatedproducts.length > 0">
              <ng-container *ngFor="let product of relatedproducts; index as i">
                <ng-template carouselSlide [id]="product.productID.toString()">
                  <div  class="p-1 d-flex">
                    <div class="slider-image">
                      <app-product-box
                        [product]="product"
                        [class]="'product-box-3'"
                        [style]="'horizontal'"
                      ></app-product-box>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </ng-container>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>
</section>
