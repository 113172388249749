@if (isBrowser) {
<app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
<app-incomplete-info-warning></app-incomplete-info-warning>
<!-- <section class="search-section">
  <div class="container-fluid-lg">
    <div class="row">
      <div class="col-xxl-6 col-xl-8 mx-auto">
        <div class="title d-block text-center">
          <h2>{{ "search_for_products" | translate }}</h2>
          <span class="title-leaf">
            <svg class="icon-width">
              <use xlink:href="assets/svg/leaf.svg#leaf"></use>
            </svg>
          </span>
        </div>

        <div class="search-box">
          <div class="input-group">
            <input type="text" class="form-control" [formControl]="search" />
            <app-button
              [class]="'btn theme-bg-color text-white m-0'"
              [type]="'button'"
              [id]="'search-btn'"
              [spinner]="false"
              (click)="searchProduct()"
            >
              {{ "search" | translate }}
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<section class="cart-section section-b-space">
  <div class="container-fluid-lg">
    <div class="row g-xl-5 g-sm-4 g-3">
      <div class="col-xxl-8 col-xl-8" style="display: grid; gap: 8px;">
        <div class="d-flex align-items-center pt-4 pb-2 gap-4 mb-3 overflow-auto">
          <button *ngFor="let btn of filters" class="div-tag pe-4 ps-4 pt-2 pb-2 bg-green"
            [class.active]="btn.active" [class]="btn.value == '1' ? 'pulse' : ''" [id]="btn.value == '1' ? 'GTT-PV' : ''" (click)="filter(btn)" style="min-width: fit-content;">
            <img *ngIf="btn.icon" [src]="btn.icon" style="width: 20px; height: 20px;" />
            <h6>{{ btn.name }}</h6>
          </button>
        </div>
        <div class="input-group align-items-center border div-search sticky">
          <input #inputSearch id="quiclSearchInput" type="search"
            class="form-control border-0 bg-transparent search-input" placeholder="Nhập tên thuốc, hoạt chất cần tìm..."
            [(ngModel)]="inputText" (keyup)="input()" />
          <button class="border-0 bg-transparent" (click)="input()">
            <i class="ri-search-line d-flex justify-content-center align-items-center search-icon"></i>
          </button>
        </div>

        <div class="cart-table ps-0 pe-0">
          @if (items.length > 0 || itemsExtend.length > 0) {
          <div class="table-responsive">
            <table class="table align-middle">
              <tbody>
                <tr *ngFor="let item of items;let i = index" class="">
                  <td>
                    <div class="d-flex gap-3">
                      <div class="div-left d-flex flex-column justify-content-center align-items-center gap-2 w-max-content">
                        <div *ngIf="isAuthenticated" class="d-flex justify-content-end w-100 pe-4">
                          <a title="Thêm vào sản phẩm yêu thích" href="javascript:void(0)" class="wishlist-button {{
                                  !checkInWishlist(item.productID) ? '' : 'active'
                                }} d-flex align-items-center justify-content-center"
                            (click)="addToWishlist(item.productID)">
                            <i class="wishlist-icon {{
                                    !checkInWishlist(item.productID) ? 'ri-heart-line' : 'ri-heart-fill fill'
                                  }} ri-lg" style="margin-top: 2px"></i>
                          </a>
                        </div>
                        <a href="javascript:void(0)" class="product-image"
                          (click)="openModal(i, item, productDetailImageModal)">
                          <img [src]="
                                  item.imageUrls.length > 0
                                    ? item.imageUrls[0]
                                    : 'assets/images/product.png'
                                " class="img-fluid" alt="product" />
                        </a>
                        <!-- <span class="align-items-center gap-1 d-none d-lg-flex d-xl-flex" style="color: #6A6A6A;">
                              <img src="assets/svg/view 1.svg" />
                              <h2 class="font-12 fw-normal text-lowercase">{{ views }} lượt xem trong 24h</h2>
                            </span> -->
                      </div>
                      <div class="product border-0 d-contents">
                        <div class="product-detail w-100">
                          <ul>
                            <li class="name text-wrap">
                              <a [routerLink]="['/product/', item.slug ]" class="link">
                                <h5>{{ item.name }}</h5>
                              </a>

                              <div class="d-flex flex-wrap gap-2 pt-2">
                                <ng-container *ngFor="let l of item.labels let i = index">
                                  <div *ngIf="l.slug" class="product-label {{ i % 2 == 0 ? 'best-seller' : 'gift' }}">
                                    <div type="button" (click)="queryStringBuilder(l.slug)">
                                      <h6>{{ l.name }}</h6>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                              <div *ngIf="item.wholesalePrices?.length" class="d-none d-xl-grid d-lg-grid wsp px-2 py-1 mt-2 gap-1">
                                <ng-container *ngFor="let wsp of item.wholesalePrices; let i = index">
                                  <div class="d-flex justify-content-between gap-1">
                                    <h6>mua từ {{ wsp.minQuantity }}:</h6>
                                    <h6 class="text-danger fw-bolder">{{ wsp.wholesalePrice | currencySymbol }}</h6>
                                  </div>
                                  <!-- <div *ngIf="i < item.wholesalePrices.length - 1" class="my-1" style="border: 1px solid var(--theme-color);"></div> -->
                                </ng-container>
                              </div>
                            </li>
                            <li class="d-lg-none d-xl-none d-flex gap-2 justify-content-between"
                              *ngIf="isAuthenticated && item.stockStatus != 8  && item.basePrice > 0">
                              <div class="my-auto d-flex align-items-center gap-2 pt-2">
                                <h3 class="price fs-4 fw-bold d-flex justify-content-end align-items-center gap-1 {{ item.pricingTablePrice >= item.basePrice ? 'theme-color' : 'text-danger' }}">
                                  {{ item.pricingTablePrice | currencySymbol }}
                              </h3>
                                <h3 class="theme-color float-end fs-6" *ngIf="item.pricingTablePrice < item.basePrice">
                                  <del class="text-content">
                                    {{ item.basePrice | currencySymbol }}
                                  </del>
                                </h3>
                                <span *ngIf="isAuthenticated && item.stockStatus != 8 && item.pricingTablePrice < item.basePrice"
                                  class="d-lg-none d-xl-none fw-bold text-danger bg-warning px-2 py-1 rounded-4">
                                  {{ -(item.basePrice-item.pricingTablePrice)/item.basePrice | percent }}
                                </span>
                              </div>
                            </li>
                              <li class="d-lg-none d-xl-none d-flex gap-2 justify-content-between"
                                *ngIf="isAuthenticated">
                              <div class="out-of-stock-label" *ngIf="item.basePrice <= 0">
                                  <h5 *ngIf="item.stockStatus < 8" class="out-of-stock-text pt-2" style="color: red;">Sản phẩm tạm thời hết hàng</h5>
                                  <h5 *ngIf="item.stockStatus == 8" class="out-of-stock-text pt-2" style="color: red;">Vui lòng hoàn thiện hồ sơ để xem giá</h5>
                              </div>
                              <div class="out-of-stock-label" *ngIf="item.basePrice > 0">
                                  <h5 *ngIf="item.stockStatus == 9" class="out-of-stock-text pt-2" style="color: red;">Vui lòng hoàn thiện hồ sơ để đặt hàng</h5>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="d-lg-none d-xl-none d-flex justify-content-between align-items-center">
                      <div class="p-2 d-flex mt-2 gap-3 flex-wrap">
                        <ng-container *ngFor="let wsp of item.wholesalePrices; let i = index">
                          <div>
                            <h6>mua từ {{ wsp.minQuantity }}</h6>
                            <h6 class="text-danger fw-bolder">{{ wsp.wholesalePrice | currencySymbol }}</h6>
                          </div>
                          <div *ngIf="i < item.wholesalePrices.length - 1" class="my-1" style="border: 1px solid var(--theme-color);"></div>
                        </ng-container>
                      </div>
                      <div class="quantity-price div-col pt-2" style="min-height: auto;" *ngIf="isAuthenticated && item.stockStatus != 8  && item.stockStatus != 9  && item.basePrice > 0">
                        <div class="cart_qty">
                          <div class="input-group">
                            <app-counter [cartItem]="item.cartItem" style="width: 100%"></app-counter>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="d-none d-xl-table-cell d-lg-table-cell">
                      <div class="mobile d-flex justify-content-end" *ngIf="isAuthenticated && item.stockStatus != 8 && item.basePrice > 0">
                      <div class="my-auto">
                        <h5 class="float-start d-none d-lg-flex d-xl-flex align-items-center gap-2 float-end">
                          <span *ngIf="item.pricingTablePrice < item.basePrice"
                            class="fw-bold text-danger bg-warning px-2 py-1 rounded-4">
                            {{ -(item.basePrice-item.pricingTablePrice)/item.basePrice | percent }}
                          </span>
                          <h5 class="price fw-bold float-end"
                            class="{{ item.pricingTablePrice >= item.basePrice ? 'theme-color' : 'text-danger' }}">
                            {{ item.pricingTablePrice | currencySymbol }}
                          </h5>
                        </h5>
                        <h6 class="theme-color float-end" *ngIf="item.pricingTablePrice < item.basePrice">
                          <del class="text-content">
                            {{ item.basePrice | currencySymbol }}
                          </del>
                        </h6>
                      </div>
                    </div>
                  </td>
                  <td class="quantity col-2 d-none d-lg-table-cell d-xl-table-cell">
                      <div class="d-flex justify-content-end" *ngIf="isAuthenticated && item.stockStatus != 8 && item.stockStatus != 9 && item.basePrice > 0 else warning">
                      <div class="quantity-price div-col">
                        <div class="cart_qty">
                          <div class="input-group">
                            <app-counter [cartItem]="item.cartItem" style="width: 100%"></app-counter>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ng-template #warning>
                      <div *ngIf="isAuthenticated">
                        <div class="out-of-stock-label" *ngIf="item.basePrice <= 0">
                            <h5 *ngIf="item.stockStatus < 8" class="out-of-stock-text" style="color: red;">Sản phẩm tạm thời hết hàng</h5>
                            <a routerLink="/account/my-business-profile"><h5 *ngIf="item.stockStatus == 8" class="out-of-stock-text" style="color: red;">Vui lòng hoàn thiện hồ sơ để xem giá</h5></a>
                        </div>
                        <div class="out-of-stock-label" *ngIf="item.basePrice > 0">
                          <a routerLink="/account/my-business-profile"><h5 *ngIf="item.stockStatus == 9" class="out-of-stock-text" style="color: red;">Vui lòng hoàn thiện hồ sơ để đặt hàng</h5></a>
                        </div>
                      </div>
                    </ng-template>
                  </td>
                </tr>

                <ng-container *ngIf="skeletonLoader && !itemsExtend.length else productContent">

                  <tr *ngFor="let item of skeletonItems">
                    <app-skeleton-product-box></app-skeleton-product-box>
                  </tr>
                </ng-container>
                <ng-template #productContent>
                  <div *ngIf="itemsExtend.length && itemsExtend.length > 0">
                    <span class="fw-bold">Có thể bạn muốn tìm</span>
                  </div>
                  <tr *ngFor="let item of itemsExtend;let i = index">
                    <td>
                      <div class="d-flex gap-3">
                        <div
                          class="div-left d-flex flex-column justify-content-center align-items-center gap-2 w-max-content">
                            <div *ngIf="isAuthenticated "
                              class="d-flex justify-content-end w-100 pe-4">
                            <a title="Thêm vào sản phẩm yêu thích" href="javascript:void(0)" class="wishlist-button {{
                                    !checkInWishlist(item.productID) ? '' : 'active'
                                  }} d-flex align-items-center justify-content-center"
                              (click)="addToWishlist(item.productID)">
                              <i class="wishlist-icon {{
                                      !checkInWishlist(item.productID) ? 'ri-heart-line' : 'ri-heart-fill fill'
                                    }} ri-lg" style="margin-top: 2px"></i>
                            </a>
                          </div>
                          <a href="javascript:void(0)" class="product-image"
                            (click)="openModal(i, item, productDetailImageModal)">
                            <img [src]="
                                    item.imageUrls.length > 0
                                      ? item.imageUrls[0]
                                      : 'assets/images/product.png'
                                  " class="img-fluid" alt="product" />
                          </a>
                          <!-- <span class="align-items-center gap-1 d-none d-lg-flex d-xl-flex" style="color: #6A6A6A;">
                                <img src="assets/svg/view 1.svg" />
                                <h2 class="font-12 fw-normal text-lowercase">{{ views }} lượt xem trong 24h</h2>
                              </span> -->
                        </div>
                        <div class="product border-0 d-contents">
                          <div class="product-detail w-100">
                            <ul>
                              <li class="name text-wrap">
                                <a [routerLink]="['/product/', item.slug ]" class="link">
                                  <h5>{{ item.name }}</h5>
                                </a>

                                <div class="d-flex flex-wrap gap-2 pt-2">
                                  <ng-container *ngFor="let l of item.labels let i = index">
                                    <div *ngIf="l.slug" class="product-label {{ i % 2 == 0 ? 'best-seller' : 'gift' }}">
                                      <div type="button" (click)="queryStringBuilder(l.slug)">
                                        <h6>{{ l.name }}</h6>
                                      </div>
                                    </div>
                                  </ng-container>
                                </div>
                                <div *ngIf="item.wholesalePrices?.length" class="d-none d-xl-grid d-lg-grid wsp px-2 py-1 mt-2 gap-1">
                                  <ng-container *ngFor="let wsp of item.wholesalePrices; let i = index">
                                    <div class="d-flex justify-content-between gap-1">
                                      <h6>mua từ {{ wsp.minQuantity }}:</h6>
                                      <h6 class="text-danger fw-bolder">{{ wsp.wholesalePrice | currencySymbol }}</h6>
                                    </div>
                                    <!-- <div *ngIf="i < item.wholesalePrices.length - 1" class="my-1" style="border: 1px solid var(--theme-color);"></div> -->
                                  </ng-container>
                                </div>
                              </li>
                              <li class="d-lg-none d-xl-none d-flex gap-2 justify-content-between"
                                *ngIf="isAuthenticated && item.stockStatus != 8 &&  item.basePrice > 0">
                                <div class="my-auto d-flex align-items-center gap-2 pt-2">
                                  <h3
                                    class="price fs-4 fw-bold d-flex justify-content-end align-items-center gap-1 {{ item.pricingTablePrice >= item.basePrice ? 'theme-color' : 'text-danger' }}">
                                    {{ item.pricingTablePrice | currencySymbol }}
                                  </h3>
                                  <h3 class="theme-color float-end fs-6" *ngIf="item.pricingTablePrice < item.basePrice">
                                    <del class="text-content">
                                      {{ item.basePrice | currencySymbol }}
                                    </del>
                                  </h3>
                                  <span *ngIf="isAuthenticated && item.stockStatus != 8 && item.pricingTablePrice < item.basePrice"
                                    class="d-lg-none d-xl-none fw-bold text-danger bg-warning px-2 py-1 rounded-4">
                                    {{ -(item.basePrice-item.pricingTablePrice)/item.basePrice | percent }}
                                  </span>
                                </div>
                              </li>
                              <li class="d-lg-none d-xl-none d-flex gap-2 justify-content-between"
                                *ngIf="isAuthenticated">
                                <div class="out-of-stock-label" *ngIf="item.basePrice <= 0">
                                    <h5 *ngIf="item.stockStatus < 8" class="out-of-stock-text pt-2" style="color: red;">Sản phẩm tạm thời hết hàng</h5>
                                    <h5 *ngIf="item.stockStatus == 8" class="out-of-stock-text pt-2" style="color: red;">Vui lòng hoàn thiện hồ sơ để xem giá</h5>
                                </div>
                                <div class="out-of-stock-label" *ngIf="item.basePrice > 0">
                                    <h5 *ngIf="item.stockStatus == 9" class="out-of-stock-text pt-2" style="color: red;">Vui lòng hoàn thiện hồ sơ để đặt hàng</h5>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="d-lg-none d-xl-none d-flex justify-content-between align-items-center">
                        <div class="p-2 d-flex mt-2 gap-3 flex-wrap">
                          <ng-container *ngFor="let wsp of item.wholesalePrices; let i = index">
                            <div>
                              <h6>mua từ {{ wsp.minQuantity }}</h6>
                              <h6 class="text-danger fw-bolder">{{ wsp.wholesalePrice | currencySymbol }}</h6>
                            </div>
                            <div *ngIf="i < item.wholesalePrices.length - 1" class="my-1" style="border: 1px solid var(--theme-color);"></div>
                          </ng-container>
                        </div>
                        <div class="quantity-price div-col pt-2" style="min-height: auto;" *ngIf="isAuthenticated && item.stockStatus != 8  && item.stockStatus != 9  && item.basePrice > 0">
                          <div class="cart_qty">
                            <div class="input-group">
                              <app-counter [cartItem]="item.cartItem" style="width: 100%"></app-counter>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="d-none d-xl-table-cell d-lg-table-cell">
                        <div class="mobile d-flex justify-content-end" *ngIf="isAuthenticated && item.stockStatus != 8 && item.basePrice > 0">
                        <div class="my-auto">
                          <h5 class="float-start d-none d-lg-flex d-xl-flex align-items-center gap-2 float-end">
                            <span *ngIf="item.pricingTablePrice < item.basePrice"
                              class="fw-bold text-danger bg-warning px-2 py-1 rounded-4">
                              {{ -(item.basePrice-item.pricingTablePrice)/item.basePrice | percent }}
                            </span>
                            <h5 class="price fw-bold float-end"
                              class="{{ item.pricingTablePrice >= item.basePrice ? 'theme-color' : 'text-danger' }}">
                              {{ item.pricingTablePrice | currencySymbol }}
                            </h5>
                          </h5>
                          <h6 class="theme-color float-end" *ngIf="item.pricingTablePrice < item.basePrice">
                            <del class="text-content">
                              {{ item.basePrice | currencySymbol }}
                            </del>
                          </h6>
                        </div>
                      </div>
                    </td>
                    <td class="quantity col-2 d-none d-lg-table-cell d-xl-table-cell">
                        <div class="d-flex justify-content-end" *ngIf="isAuthenticated && item.stockStatus != 8 && item.stockStatus != 9 && item.basePrice > 0 else warning">
                        <div class="quantity-price div-col">
                          <div class="cart_qty">
                            <div class="input-group">
                              <app-counter [cartItem]="item.cartItem" style="width: 100%"></app-counter>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ng-template #warning>
                        <div *ngIf="isAuthenticated">
                          <div class="out-of-stock-label" *ngIf="item.basePrice <= 0">
                              <h5 *ngIf="item.stockStatus < 8" class="out-of-stock-text" style="color: red;">Sản phẩm tạm thời hết hàng</h5>
                              <a routerLink="/account/my-business-profile"><h5 *ngIf="item.stockStatus == 8" class="out-of-stock-text" style="color: red;">Vui lòng hoàn thiện hồ sơ để xem giá</h5></a>
                          </div>
                          <div class="out-of-stock-label" *ngIf="item.basePrice > 0">
                            <a routerLink="/account/my-business-profile"><h5 *ngIf="item.stockStatus == 9" class="out-of-stock-text" style="color: red;">Vui lòng hoàn thiện hồ sơ để đặt hàng</h5></a>
                          </div>
                        </div>
                      </ng-template>
                    </td>
                  </tr>
                </ng-template>
              </tbody>
            </table>
          </div>
          }
        </div>
        <app-collection-paginate></app-collection-paginate>
        <app-no-data [class]="'no-data-added collection-no-data'" [image]="'assets/svg/no-product.svg'"
          [text]="'Không tìm thấy sản phẩm nào!'" [description]="'Bạn hãy thử tìm lại với từ khóa khác nhé!'"
          *ngIf="(items.length == 0 && itemsExtend.length == 0) && !skeletonLoader">
        </app-no-data>
      </div>
      <div class="col-xxl-4 col-xl-4 total">
        <app-header-cart [sidebarCartOpen]="true"></app-header-cart>
        <!-- <div class="summery-box p-sticky">
          <div class="summery-contain">
            <ul>
              <li>
                <h4>{{ "quantity" | translate }}</h4>
                <h4 class="price">{{ cartTotalItems || 0 }}</h4>
              </li>
              <li class="align-items-start">
                <h4>{{ "shipping" | translate }}</h4>
                <h4 class="price text-end">
                  {{ "cost_at_checkout" | translate }}
                </h4>
              </li>
              <li class="align-items-start">
                <h4>{{ "tax" | translate }}</h4>
                <h4 class="price text-end">
                  {{ "cost_at_checkout" | translate }}
                </h4>
              </li>
            </ul>
          </div>
          <ul class="summery-total">
            <li class="list-total border-top-0">
              <h4>{{ "total" | translate }}</h4>
              <h4 class="price theme-color">
                {{ sumTotalPrice || 0 | currencySymbol }}
              </h4>
            </li>
          </ul>
          <div class="button-group cart-button">
            <ul>
              <li>
                <a
                  class="btn btn-animation proceed-btn fw-bold"
                  [routerLink]="['/cart/']"
                >
                  {{ "view_cart" | translate }}
                </a>
              </li>
            </ul>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</section>
<app-product-detail-image-modal #productDetailImageModal [title]="title" [images]="imageUrls">
</app-product-detail-image-modal>
}